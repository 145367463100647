@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  font-size: 30px;
  font-family: 'Arvo', serif;
}
html {
  background-color:rgba(0, 0, 0, 0.641)
  /* background-image:url('./images/sky1.jpg'); */
  /* background-color: rgb(0, 162, 255); */
}
.login-error{
  font-size:20px;
  color:red;
}

a {
  color: black;
  text-decoration: none;
  color: white;
  font-size: 20px;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}


.logout-div{
  margin-right:1%;
  display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.logout-div>button{
  margin-top:1%;
}
.no-account-text{
  margin-top: 10px;
  font-size: 20px;
}
.no-account-text:hover{
cursor:pointer;
}
.go-back-text{
  margin-top: 10px;
  font-size: 20px;
}
.go-back-text:hover{
  cursor:pointer;
  }
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-div{
display: flex;
justify-content: center;
align-items: center;
}

.profile-form-div {
  padding-top:5%;
  margin-left:10%;
  display: flex;
  justify-content: left;
}
.profile-wrapper-div{
  display: flex;
    min-width: 100%;
    flex-direction: column;
    align-items: center;
  /* display: grid;
  min-width:100%;
  grid-template-columns: 100%;
  grid-template-rows: 100%; */
  
}
.profile-wrapper-div>img{
  grid-column: 1/-1;
  grid-row: 1/-1;
  filter: grayscale(100%);
  min-width:100%;
z-index: -100;  
}
.profile-dog-update-div{
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.update-div-text{
  display: flex;
  justify-content: space-between;  
  padding: 5%;
  
}
.profile-div-text{
  display: flex;
  justify-content: space-between;
  padding: 2%;
  align-items: center;
}
.update-div-text>input{
  width:50%;
  }
.button-save-info-div{
    display: flex;
    justify-content: center;
}

.profile-img{
  width: 100%;
  height: 100%;
}
.dog-profile-text{
  text-align: center;
width: 80%;
}
.profile-div {

background-position:center; 
background-color: rgba(240, 248, 255, 0.156);
 box-shadow: 1px 3px 9px 1px rgb(0 0 0 / 33%);
  margin-top: 3%;
  margin-left:10%;
  width: 80%;
  height:fit-content;
  display: flex;
  justify-content: left;
  padding-bottom: 10px;
  
}



.profile-title{
  color:black;
  font-size: 50px;
}
.profile-section{
  margin-left: 1rem;
  width: 200px;
  overflow:auto;
  background-color: white;
  color:black;
  font-size: 25px;
}

.add-profile-from{
  padding:1%;
 background: rgba(215, 213, 213, 0.593);
 box-shadow: 1px 3px 9px 1px rgba(8, 8, 9, 0.33);
  margin-top: 5%;
  margin-left:10%;
  width: fit-content; 
}

.addpet {
  margin-top: 2%;
  margin-left:10%;
  display: flex;
  justify-content: left;
}
.auth-main-div{
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
  align-items: center;
}
.auth-main-div>h1{
margin-bottom: 3%;
color: white;
	font-size: 40px;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}
.auth-form{
  
  padding: 1%;
  width:40%;
  text-align: center;
  box-shadow: 0px 2px 2px 0px;
  display: flex;
  flex-direction: column;
  background: rgb(75, 74, 74);
  height: 70%;
  align-items: center;

}
.auth-form>label{
  color: white;
	font-size: 40px;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;

}
.next-medical-appointment-div{
  display: flex;
  flex-direction: column;
 align-items: center;
}
.vet-div{
  display: flex;
  justify-content: left;
  align-items: center;
}

.auth-form>button{
  margin-top:5%;
  margin-bottom: 3%;
width:50%
}
.auth-form>button:hover{
  cursor: pointer;
}
.main-profile-div{
  
height:90vh;
background-size:cover;
background-attachment: fixed;
}


.nav-div {
  display: flex;
  padding: 5px;
  justify-content: space-evenly;
}
.nav-main-div {
  box-shadow: 0px 0px 2px 1px #000000;
  background: grey;
  opacity: 70%;
}
.nav-main-div > button {
  margin: 1px;
  border: none;
}
.nav-main-div > button:hover {
  cursor: pointer;
}
.time-div {
  display: flex;

  width: fit-content;
  background-color: white;
}
.main-tracker-div1 {
  display: flex;
  width:100%;
  justify-content: center;
}
.main-tracker-div2 {
  border-radius: 10px;
  
  color: white;
  width: 80%;
  margin-top: 2%;
  height: 80vh;
  display: grid;
  grid-template-columns: repeat(20, 5%);
  grid-template-rows: repeat(10, 10%);
}

.backgroundImg {  
  border-radius: 10px;
  filter: sepia(100%) brightness(50%);
  width: 100%;
  height: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
  z-index: 1;
}

.tracker-took-out-pet-div {
  grid-column: 2/5;
  grid-row: 2/5;
  z-index: 100;
}

.tracker-fed-pet-div {
  text-align: center;
  grid-column: 8/14;
  grid-row: 4/7;
  z-index: 100;
}
.fedpet > button .fedpet > button:hover {
  cursor: pointer;
  border: none;
  background-color: white;
  border-radius: 10px;
  margin: 1%;
}
.fedpet > button:hover {
  cursor: pointer;
}

.tracker-treat-div {
  grid-column: 16/19;
  grid-row: 2/5;
  z-index: 100;
}

.tracker-medical-div {
  grid-column: 2/7;
  grid-row: 7/9;
  z-index: 100;
}
.vetapt-date{
  text-align: center;
  width: fit-content;
 background-color: blue;
 
}
.vetapt-form-div{
display: flex;
flex-direction: column;
align-items: center;
}
.vetapt-notes{
  font-size: 14px;
  text-align: left;

  max-width:250px;
  width: fit-content;


 
}
.tracker-medical-div:hover {
  cursor: pointer;
}
.text-input-notes{
  font-size: 15px;
  text-align: left;
}


.tracker-grooming-div {
  grid-column: 16/20;
  grid-row: 7/9;
  z-index: 100;
}
.grooming-userinput-div{
  display: flex;
  flex-direction: column;
 align-items: center;
}

.tracker-grooming-div:hover {
  cursor: pointer;
}
.grooming{
  background-color: blue;
}
.tracker-time-div {
  display: flex;
justify-content: center;
align-items: center;
  font-size: 28px;
  margin-top: 5px;
  text-align: center;
  grid-column: 1/-1;
  grid-row: 1/2;
  z-index: 100;
  gap: 1%;
}

.add-new-day{
  
  font-size: 10px;
  height: fit-content;
    }

.previousNext-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.previousNext-div > button {
  margin: 1%;
  border-radius: 100%;
}
.previousNext-div > button:hover {
  cursor: pointer;
}
.time {
  color: black;
  padding-left: 2px;
  padding-right: 2px;
}
.time:hover {
  cursor: pointer;
}

.took-out-pet-title {
  text-align: center;
}
.treat-title {
  text-align: center;
}
/* @media only screen and (max-width: 1365px) {
  .profile-div{
    width:auto;
  }
} */

@media only screen and (min-width: 300px) and (max-width: 900px){
  * {
    font-size: 15px;
  }
  .add-dog-title{
    font-size: 30px;
  }
  .profile-title{
  
    font-size: 20px;
  }
  .no-account-text{
    margin-top: 10px;
    font-size: 20px;
  }
  
  .go-back-text{
    margin-top: 10px;
    font-size: 20px;
  }
  .profile-section{  
    font-size: 15px;
  }
  .profile-div {
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
  }

  .auth-form{
    padding: 1%;
    width:90%;
    text-align: center;
    box-shadow: 0px 2px 2px 0px;
    display: flex;
    flex-direction: column;
  
    height: 70%;
    align-items: center;
  
  }
  .auth-form>label{
    color: white;
	font-size: 20px;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
    margin: 1%;
    font-size: 16px;
  }
  .auth-form>button{
    margin-top:3%;
    font-size: 16px;
  }

  .vet-div{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .auth-form>input{
    max-width: 200px;
    width:70%;
  }
  .main-tracker-div2 {
    border-radius: 10px;
    
    color: white;
    width: 95%;
    margin-top: 2%;
    height: 80vh;
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(20, 5%);
  }

  .tracker-took-out-pet-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column: 1/-1;
    grid-row: 3/5;
    z-index: 100;
  }

  .tracker-fed-pet-div {
    text-align: center;
    grid-column: 1/-1;
    grid-row: 7/8;
    z-index: 100;
  }

  .tracker-treat-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column: 1/-1;
    grid-row: 10/12;
    z-index: 100;
  }

  .tracker-medical-div {
    text-align: center;
    grid-column: 1/-1;
    grid-row: 14/16;
    z-index: 100;
  }
  .tracker-grooming-div {
    text-align: center;
    grid-column: 1/-1;
    grid-row: 17/19;
    z-index: 100;
  }

  .tracker-time-div {
    display: flex;
  justify-content: center;
  align-items: center;
    font-size: 18px;
    margin-top: 5px;
    text-align: center;
    grid-column: 1/-1;
    grid-row: 1/2;
    z-index: 100;
    gap: 1%;
  }

  .add-new-day{
  
    font-size: 10px;
    height: fit-content;
      }
  .next-medical-appointment-div{
    display: flex;
    flex-direction: column;
   align-items: center;
  }
  .medical-notes-div{
    display: flex;
    flex-direction: column;
   align-items: center;
  }
  .grooming{
    text-align: center;
    width: fit-content;
   background-color: blue;
  }
  .vetapt-notes{
max-width: 300px;
  }
}

@media only screen and (min-height: 301px) and (max-height:850px ) {

*{
  font-size: 8px;
}
a{
  font-size: 13px;
}
.add-dog-title{
  font-size: 20px;
}
.add-profile-from{
  display: flex;
  flex-direction: column;
}
.profile-div {
  width: auto;
  margin-left: 5%;
  margin-right: 5%;
}
.profile-div-text{
  display: flex;
  justify-content: space-between;
 
  padding: 2%;
  flex-direction: column;
  align-items: center;
}
.auth-main-div>h1{
  margin-bottom: 3%;
  color: white;
    font-size: 20px;
    text-shadow: -1px 1px 2px #000,
            1px 1px 2px #000,
            1px -1px 0 #000,
            -1px -1px 0 #000;
  }
  .auth-form{
    padding: 1%;
    width:90%;
    text-align: center;
    box-shadow: 0px 2px 2px 0px;
    display: flex;
    flex-direction: column;
  
    height: 70%;
    align-items: center;
  
  }
.profile-title{
  
  font-size: 14px;
}
.profile-section{
align-items: center;
  font-size: 10px;
  width:auto;
  padding-left:2px;
  padding-right:2px;
}
.auth-form>label{
  color: white;
font-size: 20px;
text-shadow: -1px 1px 2px #000,
        1px 1px 2px #000,
        1px -1px 0 #000,
        -1px -1px 0 #000;
  margin: 1%;
  font-size: 16px;
}
.no-account-text{
  font-size: 10px;
}
.go-back-text{
  font-size: 10px;
}

.main-tracker-div2 {
  border-radius: 10px;
  
  color: white;
  width: 95%;
  margin-top: 2%;
  height: 80vh;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-template-rows: repeat(20, 5%);
}

.tracker-took-out-pet-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  grid-row: 3/5;
  z-index: 100;
}

.tracker-fed-pet-div {
  text-align: center;
  grid-column: 1/-1;
  grid-row: 7/8;
  z-index: 100;
}

.tracker-treat-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  grid-row: 10/12;
  z-index: 100;
}

.tracker-medical-div {
  text-align: center;
  grid-column: 3/-1;
  grid-row: 15/17;
  z-index: 100;
}
.tracker-grooming-div {
  text-align: center;
  grid-column: 1/2;
  grid-row: 15/17;
  z-index: 100;
}

.tracker-time-div {
  display: flex;
justify-content: center;
align-items: center;
  font-size: 18px;
  margin-top: 5px;
  text-align: center;
  grid-column: 1/-1;
  grid-row: 1/2;
  z-index: 100;
  gap: 1%;
}

.add-new-day{

  font-size: 10px;
  height: fit-content;
    }
.next-medical-appointment-div{
  display: flex;
  flex-direction: column;
 align-items: center;
}
.medical-notes-div{
  display: flex;
  flex-direction: column;
 align-items: center;
}
.grooming{
  text-align: center;
  width: fit-content;
 background-color: blue;
}


}

/* .tracker-list-div>div:first-child{
  height: 100vh;
 
grid-column: 1/3;
grid-row: 1/2;
}

.tracker-list-div>div:not(:first-child){
 
  font-size: 10px;
  width:auto;
  height:100px;
  

} */
